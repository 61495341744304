import { client } from '../../../client';
import GET_CHAT from './queries/getChat';

const getChat = async (
  chatId,
  isUser = true,
) => {
  const { data: { chat } } = await client.query({
    query: GET_CHAT,
    variables: {
      id: chatId,
      isVisibleByExpert: !isUser,
      isVisibleByUser: isUser,
    },
  });
  return chat;
};

export {
  getChat,
  GET_CHAT,
};
