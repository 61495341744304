import { gql } from '@apollo/client';
import chatMessageFragment from '../../fragments/chatMessageFragment';

export default gql`
  ${chatMessageFragment}
  subscription NewChatMessage(
    $id: ID!,
    $forExpert: Boolean,
    $forUser: Boolean,
  ) {
    newChatMessage(id: $id, forExpert: $forExpert, forUser: $forUser) {
      ...MessageFragment
    }
  }
`;
