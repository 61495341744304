import { gql } from '@apollo/client';
import chatMessageFragment from './chatMessageFragment';

const messagesFragment = gql`
  ${chatMessageFragment}
  fragment MessagesFragment on Chat {
    messages(
      isVisibleByExpert: $isVisibleByExpert
      isVisibleByUser: $isVisibleByUser
      orderBy: {
        direction: asc
        field: createdAt
      })
    {
      edges {
        node {
          ...MessageFragment
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export default messagesFragment;
