import { gql } from '@apollo/client';

export default gql`
  mutation OpenChat($id: ID!) {
    openChat(id: $id) {
      id
      status
    }
  }
`;
