import { gql } from '@apollo/client';

const payouts = gql`
  query GetPayouts {
    payouts {
      experts(first: 10) {
        edges {
          node {
            name
            email
            photoUrl
            balances {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

const charges = gql`
  query GetCharges {
    charges {
      partners {
        partner {
          name
        }
        balances {
          amount
          currency
        }
      }
    }
  }
`;

export {
  payouts,
  charges,
};
