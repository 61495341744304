import { gql } from '@apollo/client';

export default gql`
  query GetPersistedChatSettings(
    $id: ID!,
    $locale: String,
    $category: String,
    $token: String,
  ) {
    chatSettings(id: $id, locale: $locale, category: $category, token: $token) {
      id
      name
      organization {
        id
        color
        partners {
          id
          name
          settings {
            id
            appearance {
              chatWidgetPhotoUrl
            }
          }
        }
      }

      smartFormDelegateToClient
      smartFormDelegateQuestionSelector
      smartFormExpertSupportEnabled
      smartFormAlignment
      chatWidgetPhotoUrl
      chatWidgetPrimaryColor
      chatWidgetCustomBackground
      chatWidgetCustomBackgroundPhotoUrl
      chatWidgetFont
      chatWidgetShowBotAvatar
      chatWidgetCustomBotAvatar
      chatWidgetCustomBotAvatarPhotoUrl
      chatWidgetShowPoweredBy
      chatWidgetShowWelcomeMessage
      chatWidgetRequiresName
      chatWidgetRequiresEmail
      chatWidgetShowGreetingMessage
      chatWidgetSmsFallback
      chatWidgetRequestVisitorRole
      chatWidgetAnalyticsEnabled
      chatWidgetHeaderImageUrl
      chatWidgetShowProfiles
      chatWidgetProfilesPictures
      chatWidgetProfilesCount

      agentsTransferByEmail
      agentsEmailTimeout

      thankYouSectionShowNps
      thankYouSectionLink

      language
      category {
        id
      }
      translations
      isChatAvailable
      isTokenValid
      hasPredictions
      isEscalationsEnabled

      quickActions {
        id
        action
        assignTo
        icon
        priority
        name
        description
        response
        expertsPriority
        isEnabled
      }

      quickActionsSettings {
        quickActionRequestQuestionsTop
        quickActionSkipIfAdviceQuestion
      }

      categories {
        id
        categoryId
        name
        urlPattern
        isActive
        priority
      }

      alert {
        id
        title
        message
      }
    }
  }
`;
