import { gql } from '@apollo/client';

export default gql`
  mutation AcceptChat($id: ID!) {
    acceptChat(id: $id) {
      id
      status
      expert {
        id
      }
    }
  }
`;
