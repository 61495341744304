import { relayStylePagination } from '@apollo/client/utilities';

export default {
  Chat: {
    fields: {
      mentions: relayStylePagination(),
      messages: relayStylePagination(['orderBy', 'isVisibleByExpert', 'isVisibleByUser']),
      relatedChats: relayStylePagination(),
      requestedExperts: relayStylePagination(),
    },
  },
  Community: {
    fields: {
      experts: relayStylePagination(),
      mentions: relayStylePagination(['urls']),
    },
  },
  Expert: {
    fields: {
      allStatements: relayStylePagination(['partner']),
      chats: relayStylePagination([
        'period', 'status', 'filters', 'startDate', 'endDate', 'partnerId', 'billable',
      ]),
      news: relayStylePagination(),
      partners: relayStylePagination(['partnerId', 'hasPayments', 'period']),
      submissions: relayStylePagination(['filters', 'orderBy']),
    },
  },
  Partner: {
    fields: {
      balances: relayStylePagination(),
      cannedResponses: relayStylePagination(['prefix']),
      chats: relayStylePagination([
        'filters', 'startDate', 'endDate', 'orderBy',
      ]),
      discounts: relayStylePagination(),
      experts: relayStylePagination([
        'orderBy', 'filters', 'startDate', 'endDate',
      ]),
      members: relayStylePagination(['roles']),
      mentions: relayStylePagination(['filters']),
      news: relayStylePagination(),
      questions: relayStylePagination([
        'orderBy', 'filters', 'startDate', 'endDate',
      ]),
      submissions: relayStylePagination(['filters', 'orderBy']),
      subscriptions: relayStylePagination(['status']),
      surcharges: relayStylePagination(),
      translations: relayStylePagination(['filters']),
    },
  },
  PartnerAutomation: {
    fields: {
      intents: relayStylePagination([
        'isEnabled', 'filters', 'intentStatus', 'startDate', 'endDate',
      ]),
    },
  },
  Payouts: {
    fields: {
      experts: relayStylePagination(),
    },
  },
  Query: {
    fields: {
      abilities: relayStylePagination(['filters']),
      categories: relayStylePagination(),
      chats: relayStylePagination([
        'filters', 'startDate', 'endDate', 'orderBy',
      ]),
      experts: relayStylePagination(['filters', 'orderBy']),
      globalMembers: relayStylePagination(['roles']),
      globalNews: relayStylePagination(),
      globalTranslations: relayStylePagination(['filters']),
      intents: relayStylePagination([
        'isEnabled', 'filters', 'status', 'startDate', 'endDate', 'orderBy',
      ]),
      partners: relayStylePagination(['filters', 'orderBy']),
      questions: relayStylePagination([
        'orderBy', 'filters', 'startDate', 'endDate',
      ]),
    },
  },
};
