module.exports = {
  agents: 'agents',
  automation: 'automation',
  chat: 'chat',
  categories: 'categories',
  quickActions: 'quickActions',
  escalations: 'escalations',
  form: 'form',
  smsFallback: 'smsFallback',
  transfer: 'transfer',
  communitiesView: 'communities view',
  chatFeedbackRequest: 'chat feedback',
  community: 'community',
  recruitment: 'recruitment',
  invitationPage: 'invitationPage',
  answeringChatsPage: 'answeringChatsPage',
  rewardsPage: 'rewardsPage',
  embed: 'embedWidget',
  manageProfilePage: 'manageProfilePage',
  contentEmbed: 'contentEmbed',
  feedback: 'feedback',
  news: 'news',
};
