import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  makeVar,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import merge from 'lodash/merge';
import { resultErrorCode } from './error';
import commonTypePolicies from '../types';
import possibleTypes from '../../possible-types.json';

let wsTimeout; // will keep track of time elapsed since last ping without pong

export default class Client extends ApolloClient {
  constructor(config) {
    const {
      request,
      connection,
      uri,
      ws,
      credentials,
      headers,
      fetch,
      fetchOptions,
      clientState,
      cacheRedirects,
      onError: errorCallback,
      name,
      version,
      resolvers,
      typeDefs,
      sha256Fn,
    } = config;
    let { cache } = config;

    if (!uri || !ws) {
      throw Error('Missing CORE_ENDPOINT or CORE_WS_ENDPOINT env variables');
    }

    if (!cache) {
      const typePolicies = merge(clientState.defaults, commonTypePolicies);
      cache = cacheRedirects
        ? new InMemoryCache({ cacheRedirects, possibleTypes, typePolicies })
        : new InMemoryCache({ possibleTypes, typePolicies });
    }

    const errorLink = onError(errorCallback);

    const retryLink = new RetryLink({
      delay: {
        initial: 2500,
      },
      attempts: {
        max: 5,
        retryIf: (err) => {
          if (!err?.statusCode) return true;
          if (err.statusCode >= 500 || err.statusCode === 429) return true;
          return resultErrorCode(err?.result) === 'UNAUTHENTICATED';
        },
      },
    });

    const requestHandler = request
      ? new ApolloLink(
        (operation, forward) => new Observable((observer) => {
          let handle;
          Promise.resolve(operation)
            .then((oper) => request(oper))
            .then(() => {
              handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
            })
            .catch(observer.error.bind(observer));

          return () => {
            if (handle) {
              handle.unsubscribe();
            }
          };
        }),
      )
      : false;

    const wsClient = createClient({
      url: ws,
      retryAttempts: Infinity,
      lazy: true,
      on: {
        ping: (received) => {
          if (received) return;
          wsTimeout = setTimeout(() => wsClient.terminate(), 5000); // 5s
        },
        pong: (received) => {
          if (received) clearTimeout(wsTimeout);
        },
      },
      connectionParams: connection ?? false,
      keepAlive: 10000, // 10s
      shouldRetry: () => true,
    });
    const wsLink = new GraphQLWsLink(wsClient);

    const hybridLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition'
          && definition.operation === 'subscription'
        );
      },
      wsLink,
    );

    const httpOptions = {
      uri: uri || '/graphql',
      fetch,
      fetchOptions: fetchOptions || {},
      credentials: credentials || 'same-origin',
      headers: headers || {},
    };

    const httpLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        const opName = definition?.name?.value;
        return opName && opName.startsWith('GetPersisted');
      },
      createPersistedQueryLink({
        useGETForHashedQueries: true,
        sha256: sha256Fn,
      })
        .concat(new HttpLink(httpOptions)),
      new BatchHttpLink(httpOptions),
    );

    const link = ApolloLink.from([
      retryLink,
      errorLink,
      requestHandler,
      hybridLink,
      httpLink,
    ].filter((x) => !!x));

    let activeResolvers = resolvers;
    let activeTypeDefs = typeDefs;

    if (clientState) {
      activeResolvers = clientState.resolvers;
      activeTypeDefs = clientState.typeDefs;
    }

    super({
      cache,
      link,
      name,
      version,
      resolvers: activeResolvers,
      typeDefs: activeTypeDefs,
    });

    this.connectedAt = makeVar();
    this.close = () => wsClient.terminate();

    wsClient.on('connected', () => this.connectedAt(Date.now()));
  }
}
