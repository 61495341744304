import { gql } from '@apollo/client';
import chatFields from '../../fragments/publicChatFields';
import messagesFragment from '../../fragments/chatMessagesFragment';

export default gql`
  ${chatFields}
  ${messagesFragment}
  subscription ChatUpdated(
    $id: ID!,
    $isVisibleByExpert: Boolean,
    $isVisibleByUser: Boolean,
  ) {
    chatUpdated(id: $id) {
      id
      ...PublicChatFields
      ...MessagesFragment
    }
  }
`;
