module.exports = {
  MESSAGE_TYPE_TYPING: 'typing',
  MESSAGE_TYPE_MESSAGE: 'message',
  MESSAGE_TYPE_CONTACT: 'contact',
  MESSAGE_TYPE_ATTACHMENT: 'attachment',
  MESSAGE_TYPE_CATEGORY: 'category',
  MESSAGE_TYPE_PREDICTION_RESULT: 'predictionConfirmation',
  MESSAGE_TYPE_PREDICTION_ANSWER: 'predictionAnswer',
  MESSAGE_TYPE_REQUEST_RATING: 'requestRating',
  MESSAGE_TYPE_WAIT_TIMEOUT: 'waitTimeout',
  MESSAGE_TYPE_EMAIL_WAIT_TIMEOUT: 'emailWaitTimeout',
  MESSAGE_TYPE_SMS_FALLBACK: 'smsFallback',
  MESSAGE_TYPE_VISITOR_ROLE: 'visitorRole',
  MESSAGE_TYPE_PARTNER_SELECTION: 'partnerSelection',
  MESSAGE_TYPE_QUICK_ACTION_SELECTION: 'quickActionSelection',
  MESSAGE_TYPE_INTENT_SELECTION: 'intentSelection',
  MESSAGE_TYPE_USER_INFO: 'userInfo',
  MESSAGE_TYPE_EMOJI: 'emoji',
  MESSAGE_TYPE_WELCOME: 'welcome',
  MESSAGE_TYPE_BUTTON: 'button',
};
