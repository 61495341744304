import {
  BarChartOutlined,
  GoldOutlined,
  HomeOutlined,
  InteractionOutlined,
  MessageOutlined,
  RobotOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { GuuruOutlined } from '@guuru/react-icon';

const mainMenuContent = [
  {
    name: 'Dashboard',
    id: 'dashboard',
    icon: HomeOutlined,
    permission: 'partner.dashboard',
    route: '/',
  },
  {
    name: 'Interactions',
    id: 'interactions',
    icon: InteractionOutlined,
    permission: 'partner.conversations',
    route: '/interactions',
  },
  {
    name: 'Interaction',
    id: 'interactions',
    mpid: '-1',
    icon: MessageOutlined,
    permission: 'partner.conversation',
    route: '/interactions/:id',
  },
  {
    name: 'Content',
    id: 'content',
    icon: GoldOutlined,
    permission: 'partner.content',
    route: '/content',
  },
  {
    name: 'Automation',
    id: 'automation',
    icon: RobotOutlined,
    permission: 'partner.settings.automation',
    route: '/automation',
  },
  {
    name: 'Experts',
    id: 'experts',
    icon: TeamOutlined,
    permission: 'partner.team.experts',
    route: '/experts',
  },
  {
    name: 'Expert',
    id: 'experts-expert',
    mpid: '-1',
    permission: 'partner.team.guurus',
    route: '/experts/:id',
  },
  {
    name: 'Community',
    icon: GuuruOutlined,
    id: 'community',
    permission: 'partner.community',
  },
  {
    name: 'Highlights',
    id: 'community-highlights',
    mpid: 'community',
    permission: 'partner.highlights',
    route: '/community/highlights',
  },
  {
    name: 'Building',
    id: 'community-building',
    mpid: 'community',
    permission: 'partner.recruitment',
    route: '/building',
  },
  {
    name: 'Building',
    id: 'community-building',
    mpid: 'community-building',
    permission: 'partner.reporting',
    route: '/building/progress',
  },
  {
    name: 'Knowledge Base',
    id: 'community-pages',
    mpid: 'community',
    permission: 'partner.pages',
    route: '/pages',
  },
  {
    name: 'Updates',
    id: 'community-updates',
    mpid: 'community',
    permission: 'partner.community.updates',
    route: '/community/updates',
  },
  {
    name: 'Monitoring',
    id: 'community-monitoring',
    mpid: 'community',
    permission: 'partner.monitoring.details',
    route: '/monitoring',
  },
  {
    name: 'Month Monitoring',
    id: 'community-monitoring',
    mpid: '-1',
    permission: 'partner.monitoring',
    route: '/monitoring/:startDate/:endDate',
  },
  {
    name: 'Tasks',
    id: 'community-tasks',
    mpid: 'community',
    permission: 'partner.tasks',
    route: '/tasks',
  },
  {
    name: 'Reporting',
    id: 'reporting',
    icon: BarChartOutlined,
    permission: 'partner.reporting',
    route: '/reporting',
  },
  {
    name: 'Automation',
    id: 'reporting-automation',
    mpid: 'reporting',
    permission: 'partner.automation',
    route: '/reporting/automation',
  },
  {
    name: 'Conversion',
    id: 'reporting-conversion',
    mpid: 'reporting',
    permission: 'partner.reporting.conversion',
    route: '/reporting/conversion',
  },
  {
    name: 'Custom',
    id: 'reporting-custom',
    mpid: 'reporting',
    permission: 'partner.reporting.custom',
    route: '/reporting/custom',
  },
  {
    name: 'Settings',
    id: 'settings',
    icon: SettingOutlined,
    permission: 'partner.settings',
    route: '/settings',
  },
  {
    name: 'General',
    id: 'settings-general',
    mpid: 'settings',
    permission: 'partner.settings.profile',
    route: '/settings/general',
  },
  {
    name: 'Community',
    id: 'settings-community',
    mpid: 'settings',
    permission: 'partner.settings.community',
    route: '/settings/community',
  },
  {
    name: 'Engagement',
    id: 'settings-engagement',
    mpid: 'settings',
    permission: 'partner.chat',
    route: '/settings/engagement',
  },
  {
    name: 'Members',
    id: 'settings-members',
    mpid: 'settings',
    permission: 'partner.settings.roles',
    route: '/settings/members',
  },
  {
    name: 'Integrations',
    id: 'settings-integrations',
    mpid: 'settings',
    permission: 'partner.settings.integrations',
    route: '/settings/integrations',
  },
  {
    name: 'Developer',
    id: 'settings-developer',
    mpid: 'settings',
    permission: 'partner.settings.developer',
    route: '/settings/developer',
  },
  {
    name: 'Billing',
    id: 'settings-billing',
    mpid: 'settings',
    permission: 'partner.billing',
    route: '/settings/billing',
  },
  {
    name: 'Invoice',
    id: 'settings-billing',
    mpid: '-1',
    permission: 'partner.billing',
    route: '/settings/billing/invoices/:year/:month',
  },
];

export default mainMenuContent;
