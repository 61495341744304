import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import TagManager from 'react-gtm-module';
import ThemeProvider from '@guuru/theme-web';
import { colors } from '@guuru/constants-common';
import { Sentry, initSentry } from '@guuru/sentry-web';
import { i18n } from '@guuru/translation-web';
import { ApolloProvider } from '@apollo/client';
import { client } from '@guuru/graphql-web';
import { ErrorBoundary } from '@guuru/react-error-pages';
import { AbilityContext } from '../config/ability-context';
import ability from '../config/ability';
import MainView from '../views/MainView';
import GlobalStyle from './GlobalStyle';
import 'antd/dist/reset.css';

if (process.env.PARTNERS_GTM_ACCOUNT_ID) {
  TagManager.initialize({
    gtmId: process.env.PARTNERS_GTM_ACCOUNT_ID,
  });
}

if (process.env.PARTNERS_SENTRY_DSN) {
  initSentry(process.env.PARTNERS_SENTRY_DSN);
}

const PartnersApp = Sentry.withProfiler(() => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={{ color: colors.primaryColor }}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <AbilityContext.Provider value={ability}>
            <GlobalStyle />
            <MainView />
          </AbilityContext.Provider>
        </ErrorBoundary>
      </I18nextProvider>
    </ThemeProvider>
  </ApolloProvider>
));

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<PartnersApp />);
