const ChatStep = {
  booting: 'booting',
  organizationSelection: 'organizationSelection',
  chatInit: 'chatInit',
  chatLoad: 'chatLoad',
  chatStart: 'chatStart',
  quickActionSelection: 'quickActionSelection',
  showWelcomeMessage: 'showWelcomeMessage',
  greeting: 'greeting',
  chatQuestion: 'chatQuestion',
  handoverChat: 'handoverChat',
  makePrediction: 'makePrediction',
  requestIntentSelection: 'requestIntentSelection',
  requestCategory: 'requestCategory',
  requestVisitorRole: 'requestVisitorRole',
  requestName: 'requestName',
  requestEmail: 'requestEmail',
  connectingExpert: 'connectingExpert',
  chat: 'chat',
  closeChat: 'closeChat',
  rateBadChat: 'rateBadChat',
  rateChat: 'rateChat',
  chatEnd: 'chatEnd',
  hasError: 'hasError',
  quickActionPredict: 'quickActionPredict',
};

module.exports = ChatStep;
