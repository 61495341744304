import { getCurrentUser } from '@guuru/auth-web';
import {
  client as Client,
  errorMessage,
  errorCode,
  errorType,
} from '@guuru/graphql-common';
import errorCallback from './errors';
import tracingHeaders from './tracing';
import sha256Fn from './sha';

const client = Client({
  getCurrentUser,
  errorCallback,
  tracingHeaders,
  sha256Fn,
});

export {
  client,
  errorCode,
  errorMessage,
  errorType,
};
