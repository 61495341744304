import { gql } from '@apollo/client';

export default gql`
  mutation CloseChat($id: ID!) {
    closeChat(id: $id) {
      id
      status
    }
  }
`;
