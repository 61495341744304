import { gql } from '@apollo/client';

export default gql`
  query GetExpertRoles($id: ID!) {
    expert(id: $id) {
      id
      name
      photoUrl
      email
      roles {
        id
        role
        partner {
          id
        }
      }
      abilities
    }
  }
`;
