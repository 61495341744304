import { gql } from '@apollo/client';

const chatFields = gql`
  fragment PublicChatFields on Chat {
    id
    question
    createdAt
    acceptedAt
    ratedAt
    status
    isAutoRated
    closedAt
    rating
    npsRating
    languageCode
    isAssignedToAgent
    lastSeenByExpertAt
    lastUserMessageAt
    expertIsTyping
    userIsTyping
    shouldBeContacted
    channel
    category {
      id
      name
    }
    isAutomated {
      id
      action
      label
      description
      topic{
        id
        title
      }
      data
      title
    }
    user {
      id
      name
      email
    }
    expert {
      id
      name
      photoUrl
    }
    partner {
      id
    }
    transfer {
      id
      confirmedAt
    }
    relatedTimes {
      effectiveResponseTime
    }
    feedback {
      chatId
      comment
      topics {
        id
        title
        type
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export default chatFields;
