import { gql } from '@apollo/client';
import chatMessagesFragment from '../../fragments/chatMessagesFragment';
import chatFields from '../../fragments/publicChatFields';

export default gql`
  ${chatFields}
  ${chatMessagesFragment}
  mutation CreateChat(
    $input: ChatInput!,
    $isVisibleByExpert: Boolean = false,
    $isVisibleByUser: Boolean = true,
  ) {
    createChat(input: $input) {
      ...PublicChatFields
      ...MessagesFragment
      token
    }
  }
`;
