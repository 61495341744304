import { gql } from '@apollo/client';

export default gql`
  fragment IntentSubscription on IntentSubscriptionInterface {
    id
    label
    action
    title
    titleExtra
    description
    score
    ... on ShowAnswerPrediction {
      subscriptionAnswer
      allowFallback
      subscriptionTarget
      subscriptionPriority
      subscriptionOptions {
        name
        option
      }
      subscriptionOptionsText
    }
    ... on RoutingPrediction {
      subscriptionTarget
      subscriptionPriority
      subscriptionOptions {
        name
        option
      }
      subscriptionOptionsText
    }
  }
`;
