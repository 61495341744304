import { gql } from '@apollo/client';
import chatFields from '../../../../fragments/publicChatFields';
import chatMessagesFragment from '../../../../fragments/chatMessagesFragment';

const GET_CHAT = gql`
  ${chatFields}
  ${chatMessagesFragment}
  query GetChat(
    $id: ID!,
    $isVisibleByExpert: Boolean,
    $isVisibleByUser: Boolean,
    $chatControlToken: String,
  ) {
    chat(id: $id, chatControlToken: $chatControlToken){
      ...PublicChatFields
      ...MessagesFragment
    }
  }
`;

export default GET_CHAT;
